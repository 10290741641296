'use client'

import { PWAInstallElement } from '@khmyznikov/pwa-install'
import PWAInstall from '@khmyznikov/pwa-install/react-legacy'
import { useEffect, useRef, useState } from 'react'

import { usePWAStore } from '@/store'

const PWAInstaller = () => {
  const setInstallerRef = usePWAStore((state) => state.setInstallerRef)
  const [showInstaller, setShowInstaller] = useState(false)
  const pwaInstallRef = useRef<PWAInstallElement>(null)

  useEffect(() => {
    console.log('pwaInstallRef', pwaInstallRef)
    setInstallerRef(pwaInstallRef)
  }, [pwaInstallRef, setInstallerRef])

  useEffect(() => {
    const UA = navigator.userAgent
    const IOS = UA.match(/iPhone|iPad|iPod/)
    const standalone = window.matchMedia('(display-mode: standalone)').matches
    const INSTALLED = !!(standalone || (IOS && !UA.match(/Safari/)))
    console.log({
      UA,
      IOS,
      standalone,
      INSTALLED,
    })
    setShowInstaller(!INSTALLED)
  }, [])

  if (!showInstaller) return null

  return (
    <PWAInstall
      ref={pwaInstallRef}
      name="Balance"
      icon="icons/icon_x512.png"
      description={`Good Balance is your partner in achieving balance and fulfillment. Assess and improve your life's harmony with ease.`}
      manifest-url="/manifest.json"
      disableScreenshots={true}
      manualApple={true}
      manualChrome={true}
    />
  )
}

export default PWAInstaller
